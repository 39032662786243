import React from "react";
import PropTypes from "prop-types";
import staticService from "../../../../services/static.service";
import backArrowIcon from "../../../../assets/img/svg/backArrowGreen.svg";
import { DESTINATION_MENU, EVENTS_MENU, GETHELI_BUSINESSES_MENU } from "../PublicMobileMenu";

const MainStep = (props) => {
  const { handleSelectSecondStep } = props;

  const handleRedirect =
    (url, anchor = "") =>
    () => {
      localStorage.setItem("scroll", anchor);
      window.location.href = url;
    };

  return (
    <ul className="gh-side-menu">
      <li className="header-desktop-menu-item">
        <a href="/helicopterhire" className="link">
          {staticService.findByAlias("bespokeMenu")}
        </a>
      </li>

      <li
        className="header-desktop-menu-item"
        onClick={() => handleSelectSecondStep(DESTINATION_MENU)}
      >
        <span className="subnavbtn mobile-subnavbtn link">
          {staticService.findByAlias("helicopterCharterInEuropeMenu")}
        </span>
        <img src={backArrowIcon} alt="next" className="gh-icon" />
      </li>

      <li className="header-desktop-menu-item" onClick={() => handleSelectSecondStep(EVENTS_MENU)}>
        <span className="subnavbtn mobile-subnavbtn link">
          {staticService.findByAlias("hlicopterCharterToEventsMenu")}
        </span>
        <img src={backArrowIcon} alt="next" className="gh-icon" />
      </li>

      {/*<li className="header-desktop-menu-item">*/}
      {/*  <a href="/transfers" className="link">*/}
      {/*    {staticService.findByAlias("shuttleMenu")}*/}
      {/*  </a>*/}
      {/*</li>*/}

      <li className="header-desktop-menu-item">
        <a href="/tours" className="link">
          {staticService.findByAlias("helicopterToursMenu")}
        </a>
      </li>

      <li className="header-desktop-menu-item --subnav">
        <span className="link" onClick={() => handleSelectSecondStep(GETHELI_BUSINESSES_MENU)}>
          {staticService.findByAlias("techSolutionsMenu")}
          <img src={backArrowIcon} alt="next" className="gh-icon" />
        </span>
      </li>
    </ul>
  );
};

MainStep.propTypes = {
  handleSelectSecondStep: PropTypes.func,
};

export default MainStep;
